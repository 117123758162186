@font-face {
	font-family: pixel;
	src: url(./layout/files/fonts/Minecraft.ttf);
}

* {
	box-sizing: border-box; 
}
html {
	-webkit-text-size-adjust: 100%; 
}
body {   
	padding: 0; 
	margin: 0px;  
	font-family:pixel;  
	letter-spacing: 2px;
    background-color: #7da269;
}
a {
	text-decoration: none;
}
.header{
	font-size: 40px;
    padding: 30px;
    line-height: 40px;
}
.bolded {
    font-weight: bold;
    font-size: 25px; 
}
.scrollBar::-webkit-scrollbar-track{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #fff;
}
.scrollBar::-webkit-scrollbar{
  width: 8px;
  background-color: #fff;
}
.scrollBar::-webkit-scrollbar-thumb{
  background-color: #c1c1c1;
} 

@media only screen and (max-width:736px) {
    .header{
        font-size: 28px;
    }
}

img{
    -khtml-user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}


/* mainButton */
.mainButton {
	font-family:pixel;  
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #195aabc4;
    border: 1px solid #fbc400;
    opacity: 0.8;
    cursor: pointer; 
    font-size: 20px; 
    height: 50px;
    margin: 0 auto;
    max-width: 260px;  
    position: relative;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    z-index: 1; 
    color: #fff; 
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    .hover {
        bottom: 0;
        left: 0;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
        span {
            background: #95554f;
            content: '';
            display: block;
            height: 10px;
            left: -15px;
            position: relative;
            width: 0;
            &:after {
                background: #fff;
                content: '';
                display: block;
                height: 10px;
                position: absolute;
                right: -10px;
                width: 10px;
            }
        }
        span:first-child {
            left: -75px;
            transition: all 0.5s steps(8);
            &:after {
            background: rgba(0,0,0,0.35);
            }
        }
        span:nth-child(2) {
            left: -45px;
            transition: all 0.425s steps(8);
        }
        span:nth-child(3) {
            left: -55px;
            transition: all 0.45s steps(8);
            &:after {
            background: rgba(0,0,0,0.35);
            }
        }
        span:nth-child(4) {
            transition: all 0.4s steps(8);
        }
        span:nth-child(5) {
            left: -25px;
            transition: all 0.475s steps(8);
            &:after {
            background: rgba(0,0,0,0.35);
        }
    }
}
&:hover {
    color: #fff;
    .hover {
        span:first-child {
            transition: all 0.4s steps(8);
            width: calc(100% + 76px);
        }
        span:nth-child(2) {
            transition: all 0.375s steps(8);
            width: calc(100% + 46px);
        }
        span:nth-child(3) {
            transition: all 0.35s steps(8);
            width: calc(100% + 56px);
        }
        span:nth-child(4) {
            transition: all 0.3s steps(8);
            width: calc(100% + 16px);
        }
        span:nth-child(5) {
            transition: all 0.325s steps(8);
            width: calc(100% + 26px);
        }
    }
}
}



/* Loader - SPIN CUBE */
// .loadContainer {
//     position: absolute; 
//     left: 50%;
//     -webkit-transform: translate(-50%,-50%);
//         -ms-transform: translate(-50%,-50%);
//             transform: translate(-50%,-50%);
//     margin-top: 165px; 
// }
// @media only screen and (max-width: 1050px) { .loadContainer { margin-top: 60px;  }} /* for stupid mobile center div */
// @-webkit-keyframes loader {
//     0% { left: -100px }
//     100% { left: 110%; }
// }
// @keyframes loader {
//     0% { left: -100px }
//     100% { left: 110%; }
// }
// .load-box {
//     width: 50px;
//     height: 50px;
//     background: #2e9fd3; /* #8dacdc; */
//     -webkit-animation: animate .5s linear infinite;
//             animation: animate .5s linear infinite; 
//     top: 0;
//     left: 0;
//     border-radius: 3px;
// }
// @-webkit-keyframes animate {
//     17% { border-bottom-right-radius: 3px; }
//     25% { -webkit-transform: translateY(9px) rotate(22.5deg); transform: translateY(9px) rotate(22.5deg); }
//     50% {
//     -webkit-transform: translateY(18px) scale(1,.9) rotate(45deg) ;
//             transform: translateY(18px) scale(1,.9) rotate(45deg) ;
//     border-bottom-right-radius: 40px;
//     }
//     75% { -webkit-transform: translateY(9px) rotate(67.5deg); transform: translateY(9px) rotate(67.5deg); }
//     100% { -webkit-transform: translateY(0) rotate(90deg); transform: translateY(0) rotate(90deg); }
// }
// @keyframes animate {
//     17% { border-bottom-right-radius: 3px; }
//     25% { -webkit-transform: translateY(9px) rotate(22.5deg); transform: translateY(9px) rotate(22.5deg); }
//     50% {
//     -webkit-transform: translateY(18px) scale(1,.9) rotate(45deg) ;
//             transform: translateY(18px) scale(1,.9) rotate(45deg) ;
//     border-bottom-right-radius: 40px;
//     }
//     75% { -webkit-transform: translateY(9px) rotate(67.5deg); transform: translateY(9px) rotate(67.5deg); }
//     100% { -webkit-transform: translateY(0) rotate(90deg); transform: translateY(0) rotate(90deg); }
// } 
// .load-shadow { 
//     width: 50px;
//     height: 5px;
//     background: #092437;
//     opacity: 0.5; 
//     top: 59px; 
//     position: absolute;
//     left: 0;
//     border-radius: 50%;
//     -webkit-animation: shadow .5s linear infinite;
//             animation: shadow .5s linear infinite;
// }
// @-webkit-keyframes shadow {
//   50% {
//     -webkit-transform: scale(1.2,1);
//             transform: scale(1.2,1);
//   }
// }
// @keyframes shadow {
//   50% {
//     -webkit-transform: scale(1.2,1);
//             transform: scale(1.2,1);
//   }
// }
/* end SPIN CUBE */
.pokePunkExamplesWrap {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.pokePunkExamplesWrap img {
    height: auto;
    width: 20%;  
    border: 1px solid #fbc50093;
}


@media only screen and (max-width:736px) {
    .pokePunkExamplesWrap img {
        width: 50%; 
    } 
    .pokePunkExamplesWrap img:nth-child(6),
    .pokePunkExamplesWrap img:nth-child(7),
    .pokePunkExamplesWrap img:nth-child(8),
    .pokePunkExamplesWrap img:nth-child(9) {
        display: none;
    }
}
.topButtonsContainer {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: #fff;
    margin-top: -300px;
}

.LogoContainer {
    height: 160px;
    background-image: url("../../files/images/logoLW.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 550px; 
    margin-bottom: 60px;
    transition: 0.3s;
}

@media only screen and (max-width:1024px) {
    .LogoContainer {
        background-size: 350px; 
    }
} 
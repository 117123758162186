.specsContainer {
    display: inline-block; 
    text-align: center; 
    font-size: 17px; 
    text-align: center;
    line-height: 25px;
    padding: 0px 20px 0px 20px;
    overflow: hidden;
}

/* COLOURS */
.coloursContainer {
    display:flex; 
    font-size:17px; 
    text-align:center;
    line-height:30px;
    margin-bottom:20px;
}

.colourBox {
    display: flex;
    flex-direction: column;
    height: 90px;
    width: 200px; 
    justify-content: center;
    color: #fff;
    font-size: 30px;
    transition: 0.3s;
    &.cb1 { background-color: #638595;  }
    &.cb2 { background-color: #935354;  }
    &.cb3 { background-color: #8e6fb5;  }
    &.cb4 { background-color: #27b27c;  }
    &.cb5 { background-color: #81dddc;  }
    &.cb6 { background-color: #fdd324;  }
    &.cb7 { background-color: #e35926;  }
    &.cb8 { background-color: #f63136;  }
}

.magStrip {
    width: 100%;
    height: 100%;
    vertical-align: middle;
    top: 0;
    left: 0; 
    margin-bottom: 20px;
}

.statsContainer {
    background: #27b27c;
    padding: 30px;
    width: 600px;
    margin: 50px auto;
    overflow: hidden;
    border-radius: 30px; 
    border: 5px solid #ccffec;
    box-shadow: 0px 0px 9px 4px #cbffeb;
    .statsTitle {
        font-size: 50px;
        padding-top: 30px;
        height: 80px;
        font-weight: bold;
    }
    .bulbaStats {
        margin-top: -9px;
        width: 200px;
    }
    .bulbaImg {
        padding:20px;
    }
    .mintText {
        padding-top:20px
    }
}
.stamp {
    width: 25px;
}


@media only screen and (max-width: 1024px) {
    .colourBox {
        font-size: 20px;
        height: 70px;
    }

    .statsContainer {  
        padding: 10px;
        width: 100%;
        margin: 30px auto; 
        border-radius:0px; 
    }
}
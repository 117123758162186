.roadMapContainer {
    overflow: hidden;
}
.roadMapTitle {
    text-align: center;
}

.imgRMContainer {
    width: 100%;
    text-align: center;
    img {
        width: 400px;
        padding: 20px;
    }
}
@media only screen and (max-width: 1024px) {
    .imgRMContainer {
        img {
            width: 300px;
            padding: 0px;
            padding-top: 40px;
        }
    }
}


/* TIMELINE RESTYLING */
.vertical-timeline-element-content .vertical-timeline-element-date, .vertical-timeline-element-content p {
    font-size: 17px !important;
}
.vertical-timeline-element-icon {
    svg {
        width: 44px !important;
        height: 44px !important;
        margin-left: -21px !important;
        margin-top: -21px !important;
    }
}
.vertical-timeline-element-content {
    background: #2d689e !important;
    color: #fff;
    box-shadow: 0px 0px 6px 4px #435738 !important;
    a { 
        color: #e1bd15;
    }
}
.vertical-timeline-element-content-arrow {
    border: 12px solid transparent !important;
    border-right: 17px solid #2d689e !important; 
}

@media only screen and (max-width: 1024px) {
    .vertical-timeline-element-content-arrow { 
        border: 9px solid transparent !important;
        border-right: 14px solid #2d689e !important;
        top: 12px !important;
    }
    .vertical-timeline-element-icon {
        svg {
            margin-left: -22px !important;
            margin-top: -22px !important;
        }
    }   
    .vertical-timeline {
        overflow: hidden !important;
        padding: 2em 4px !important;
    }
}

.filterContainer {
    display: flex;
    width: 100%; 
    max-width: 1920px;
    margin: auto;
    height: calc(100vh - 91.25px);
}
.filterSelections {
    display: flex;
    flex-direction: column; 
    width: 20%; 
    max-width: 300px;
    min-width: 300px; 
    height: 100%;
    overflow-y: scroll;
}
.filterResultsContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    align-items: flex-start;
    place-content: baseline;
/*     a {
        display: flex;
        width: 16.6666%;
    } */
}
.pokePunkzResultBoxInner img {
    width: 100%;
}
.moreToCome {
    text-align: center;
    padding: 20px;
    font-weight: bold;
}

.resetFilterBtn {
    width: 100%;
    font-size: 22px; 
    -webkit-appearance: none;
    cursor: pointer;
    transition: 0.3s;
    text-align: center;
    background: #fbc400; 
    color: #215aaa;
    padding: 10px;
    z-index: 99;
    top: 0;
    position: sticky;
    &:hover {
        background: #fbc400;
    }
} 
@media only screen and (max-width:1024px){
    .filterContainer {
        display: block;
        height: calc(100vh - 50px);
    } 
    .filterSelections {
        width: 100%;
        min-width: 100%;
        height: 35%;
    }
    .filterResultsContainer {
        height: 65%;
        img {
            width: 25%;
        }
    }
}
@media only screen and (max-width:736px){
    .filterResultsContainer {
        img {
            width: 50%;
        }
    }
    .resetFilterBtn {
        font-size: 16px;
    }
}


.tempComingSoon {
    width: 100%;
    height: 30vh;
    display: flex;
    flex-direction: column;
    font-size: 50px;
    text-align: center;
    justify-content: center;
}



 

/* Pokeball */
.option {
    display: flex;
    flex-direction: row; 
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    opacity: 1;
    transition: opacity 250ms ease-in-out; 
    padding-left: 10px;
    background: #95554f;
    &.faded { 
        background: #195aabc4;
    }
    &:hover { 
    }
    &.mainButton {
        max-width: 300px;
        padding: 3px;
    }
}
.pokeball {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    border: 1px solid #333;
    transform-origin: center bottom;  
    margin-right: 10px;
    .base {
        background: #333;
        height: 100%;
        width: 100%; 
    }
    .upper-half {
        position: absolute;
        background: #f15324;
        height: 42%;
        width: 100%;
    }
    .lower-half {
        bottom: 0;
        position: absolute;
        background: #fff;
        height: 42%;
        width: 100%;
    }
    .inner-circle {
        border-radius: 50%;
        height: 40%;
        width: 40%;
        position: absolute;
        border: 2px solid black; 
        top: 50%;
        left: 50%;
        border: 2px solid #333;
        transform: translate(-50%, -50%);
    }
}
.indicator-inner {
    border-radius: 50%;
    height: 30%;
    width: 30%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
}
.indicator {
    border-radius: 50%;
    height: 80%;
    width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #f64c4c;
    background: -webkit-radial-gradient(center, #f64c4c, #ff514f00);
    background: -moz-radial-gradient(center, #f64c4c, #ff514f00);
    background: radial-gradient(ellipse at center, #f64c4c, #ff514f00);
    opacity: 0;
}
@keyframes blink {
    0% { opacity: 0; }
    50% { opacity: 0.5; }
    100% { opacity: 0; }
}
@keyframes shake {
    0% {transform: rotate(5deg);}
    5% { transform:rotate(-5deg);}
    10% {transform: rotate(5deg);}
    15% { transform:rotate(-5deg);}
    20% {transform: rotate(5deg);}
    25% {transform: rotate(0deg);}
    100% {transform: rotate(0deg);}
}
.selected {
    animation: shake 1.5s ease-in infinite;
    .indicator {
        opacity: 1;
        animation: blink 1s ease-in-out infinite;
    }
    .indicator-inner {
        background: #f15324;
    }
}

@media only screen and (max-width:1024px){ 
    .option.mainButton {
        max-width: 100%;
        padding: 3px;
    }
    .pokeball {
        height: 37px;
        width: 37px;
    }
}


 
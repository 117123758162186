.bioContainer {
    width: 100%;
    display: inline-block; 
    text-align: center;
    overflow: hidden;
}
.bioText {
    font-size: 17px; 
    text-align: center;
    line-height: 30px; 
    padding: 0px 20px 0px 20px;
}


.bioBoxContainer {
    display: flex; 
    flex-direction: column; 
    max-width: 600px;
    margin: auto;
    padding: 20px;
}

.bioBox {
    display: flex;    
    justify-content: center;
}
.bioBoxValue {
    display: flex;
    width: 50%;
    align-items: center;
    text-align: left; 
    font-size: 17px;
} 
.bioBoxImages {
    width: 50%;
    text-align: center; 
    img {
        width: 200px;
    }
}

@media only screen and (max-width: 1024px) {
    .bioBox {
        text-align: center;
    }
    .bioBoxValue {
        width: 100%;
        justify-content: left; 
    }
    .bioBoxImages {
        text-align: right;
        img {
            width: 160px;
        }
    }
}
.OpenSeaBoxContainer {
    display: flex;
    background: #2d689e;
    font-size: 17px;
    padding: 20px;
    line-height: 6vw;
    text-align: center; 
    font-size: 22px;   
    margin-top: 20px;
    .openseaBtn { 
        color: #fff;
        margin: auto;
        background: #e1bd15;
        cursor: pointer;
        transition: 0.3s;  
    }
    .openseaBtn .mainButton {
        max-width: 300px;
    }
    .packs_og {
        width: 50%;
    }
}

@media only screen and (max-width:1024px) {  
    .OpenSeaBoxContainer {
        flex-direction: column;
        .packs_og {
            width: 100%; 
            padding-top: 20px;
        }
    }
}
.teamContainer {
    width: 100%;  
    z-index: 9; 
    top: 20px;
    right: 0; 
    font-size: 17px;
    padding: 20px;
    text-align: center;
    line-height: 30px;
    .socialImage {
        width: 35px;
        height: 35px;
    }
    a {
        width: 60px;
        height: 60px;
        margin: 2px;
        cursor: pointer; 
    }
}
.teamMember {
    width: 310px;
}
.teamFollow {
    width: 200px;
}
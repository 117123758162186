.pokePunkStatContainer {
    position: relative;
    display: flex;
    width: 12.5%;

    height: auto; 
    cursor: pointer; 
    padding: 2px;
    background:#435738;
    img {
        width: 100%;
        height: 100%;
    }
    .statContainerOff {
        display: none;
    }
    .statContainer {
        position: absolute;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        background-color: #000000cf;
        .stat {
            color: #fff;  
            font-size: 13px;
            height: 25%;
            display: flex;
            flex-direction: column;
            justify-content: center; 
        }
        .statName { 
            height: 25%;
            font-weight: bold;
            font-size: 17px;
            padding-left: 5px;
            border-bottom: 1px solid white;
        }
        .statBars {
            font-size: 18px;
            height: 25%;
            text-align: right; 
            padding-bottom: 1px;
        }
    } 
    .firstEdImg {
        width: 24px;
        height: 17px;
        top: 10px;
        right: 10px;
        position: absolute;
        animation: pulseImg 1s linear infinite;
    }
    @keyframes pulseImg {
        0% {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }
        25% {
            -webkit-transform: scale(1.2);
            transform: scale(1.2);
        }
        50% {
           -webkit-transform: scale(1.3);
           transform: scale(1.3);
        }
        100% {
            -webkit-transform: scale(1.4);
           transform: scale(1);
        }
    }
    .statChartContainer {
        display: flex;
        position: relative;
        height: 75%;
        width: 100%;
    } 
    .statValues {
        flex: 1;
    }
} 




@media only screen and (max-width:1690px) {
    .pokePunkStatContainer {
        width: 16.66666%;
    }
}
@media only screen and (max-width:1324px) {
    .pokePunkStatContainer {
        width: 25%;
    }
}
@media only screen and (max-width:736px) {
    .pokePunkStatContainer {
        width: 50%;
    }
}
.socialsContainer {
    width: 100%; 
    position: absolute;
    z-index: 9; 
    top: 20px;
    right: 0;
    text-align: right;
    img {
        width: 30px;
        height: 30px; 
    }
    a {
        width: 60px;
        height: 60px;
        margin: 2px;
        cursor: pointer; 
    } 
} 
.socialBtn {
    background: #195aab;
    border: 1px solid #fbc400;
    margin-right: 5px;
    opacity: 0.8; 
    cursor: pointer;  
    outline: 0; 
    position: relative;
    text-align: center; 
    width: 70px; 
    height: 50px;  
    .hover {
        bottom: 0;
        left: 0;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
        span {
                background: #95554f;
                content: '';
                display: block;
                height: 10px;
                left: -15px;
                position: relative;
                width: 0;
                &:after {
                    background: #fff;
                    content: '';
                    display: block;
                    height: 10px;
                    position: absolute;
                    right: -10px;
                    width: 10px;
                }
            }
            span:first-child {
                left: -75px;
                transition: all 0.5s steps(8);
                &:after {
                background: rgba(0,0,0,0.35);
                }
            }
            span:nth-child(2) {
                left: -45px;
                transition: all 0.425s steps(8);
            }
            span:nth-child(3) {
                left: -55px;
                transition: all 0.45s steps(8);
                &:after {
                background: rgba(0,0,0,0.35);
                }
            }
            span:nth-child(4) {
                transition: all 0.4s steps(8);
            }
            span:nth-child(5) {
                left: -25px;
                transition: all 0.475s steps(8);
                &:after {
                background: rgba(0,0,0,0.35);
                }
        }
    }
    &:hover {
        color: #fff;
        .hover {
            span:first-child {
                transition: all 0.4s steps(8);
                width: calc(100% + 76px);
            }
            span:nth-child(2) {
                transition: all 0.375s steps(8);
                width: calc(100% + 46px);
            }
            span:nth-child(3) {
                transition: all 0.35s steps(8);
                width: calc(100% + 56px);
            }
            span:nth-child(4) {
                transition: all 0.3s steps(8);
                width: calc(100% + 16px);
            }
            span:nth-child(5) {
                transition: all 0.325s steps(8);
                width: calc(100% + 26px);
            }
        }
    }
}

 
@media only screen and (max-width:1024px) {
    .socialsContainer { 
        text-align: center;
    }
} 